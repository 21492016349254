.navbar-admin-menu {
  display: none;
}

.navbar-mobile-menu {
  display: none;
}

.mobile-checkbox-opened {
  user-select: none;
  min-width: 32px;
  height: 32px;
  display: none;
  margin-bottom: 0;
  margin-left: var(--vds-spacing-20);
  border-radius: var(--vds-circle);
  background: none;
  position: relative;

  & > label {
    min-width: 32px;
    height: 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  &:focus-visible {
    outline: none;
  }

  &:focus-visible::after {
    content: "";
    height: 100%;
    width: 32px;
    border: 1px solid var(--vds-blue-6);
    box-shadow: 0 0 0 4px var(--vds-other-blue-015);
    position: absolute;
  }

  &:hover {
    background-color: var(--vds-gray-4);
  }
}

.hamburger {
  width: 18px;
  height: 2px;
  background: var(--vds-gray-1);
  display: block;
  position: absolute;
  top: 45%;
  left: 74%;
  margin-left: -9px;
  margin-top: -1px;
  transition: transform 200ms;
}

.hamburger1 {
  transform: translate3d(0, -6px, 0);
}

.hamburger2 {
  transform: translate3d(0, 0, 0);
}

.hamburger3 {
  transform: translate3d(0, 6px, 0);
}

.hamburger-mobile {
  width: 20px;
  height: 1.5px;
  background: var(--vds-gray-10);
  border: 1px;
  display: block;
  margin-top: -1px;
  transition: transform 200ms;
  border-radius: var(--vds-round-4);
}

.mobile-menu {
  display: none;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 230px;
  position: absolute;
  top: 51px;
  right: 0;
  flex: 1;
  z-index: var(--vds-layer-level-100);
  background-color: var(--vds-gray-1);
  box-shadow: var(--vds-light-shadow-3);
  font-family: var(--main-font-family), serif;
  font-style: normal;
  font-weight: var(--cc-font-weight-medium);
  font-size: var(--cc-font-size-13);
  line-height: var(--cc-line-height-20);
  height: calc(100vh - 50px);
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  .mobile-container {
    width: 100%;
  }

  .scroll-list {
    overflow: hidden scroll;
    padding: var(--vds-spacing-8) 0;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .dropdown {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    font-size: var(--cc-font-size-14);
    font-weight: var(--cc-font-weight-light);

    &:not(:last-of-type)::after {
      content: "";
      height: 1px;
      background-color: var(--vds-gray-4);
      margin: var(--vds-spacing-8) 0;
      width: 100%;
    }

    p {
      width: 100%;
      color: var(--vds-gray-7);
      padding: var(--vds-spacing-10) 0 var(--vds-spacing-10) var(--vds-spacing-20);
      user-select: none;
      text-transform: capitalize;
      margin: 0;
    }

    a {
      font-weight: var(--cc-font-weight-normal);
    }

    & > p {
      font-size: var(--cc-font-size-13);
      font-weight: var(--cc-font-weight-medium);
    }

    .padding-list {
      width: 100%;
      height: 30px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }

    div {
      width: 100%;

      &._current a {
        background-color: var(--vds-blue-1) !important;
        color: var(--vds-blue-6);
      }

      a {
        display: block;
        padding: var(--vds-spacing-10) var(--vds-spacing-10) var(--vds-spacing-10) var(--vds-spacing-20);
        color: var(--vds-gray-10);

        &:hover {
          background-color: var(--vds-gray-2);
          color: var(--vds-blue-6);
        }

        &:active {
          background-color: var(--vds-gray-3);
        }
      }
    }
  }
}

@media only screen and (min-width: 1px) and (max-width: 1260px) {
  .mobile-checkbox-opened {
    display: flex;
  }

  .separator.burger-menu {
    display: block;
  }
}

@media only screen and (min-width: 1px) and (max-width: 1023px) {
  .auth .login-gradient {
    display: none;
  }
}

@media only screen and (min-width: 1261px) {
  .mobile-menu {
    display: none !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1260px) {
  .container {
    padding-right: var(--vds-spacing-10);
    padding-left: var(--vds-spacing-10);
  }
}
