.two-factor-top-hat {
  background-color: var(--vds-blue-6);
  display: flex;
  align-items: center;
  color: var(--vds-gray-1);
  justify-content: center;
  gap: var(--vds-spacing-6);
  font-size: var(--cc-font-size-13);
  line-height: var(--cc-line-height-20);
  padding: var(--vds-spacing-6) 0;
  font-family: var(--main-font-family), sans-serif;
}

.two-factor-top-hat-link {
  color: var(--vds-gray-1);
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: var(--vds-gray-1);
  }
}
