.per-page {
  font-size: var(--cc-font-size-18);

  a,
  span {
    font-weight: var(--cc-font-weight-normal);
    margin: 0 5px;
    position: relative;
  }
}

.list-navigation {
  margin: 30px 0;
  height: 44px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .per-page {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    white-space: nowrap;
    font-weight: var(--cc-font-weight-normal);

    .csv-container {
      margin-left: 30px;
      color: var(--cc-color-blue-10);
      text-decoration: none;

      &:hover {
        color: var(--cc-color-blue-5);
        text-decoration: none;
      }
    }

    .disabled {
      color: var(--cc-color-base-font-color);

      &:hover {
        color: var(--cc-color-base-font-color);
        cursor: default;
      }
    }
  }
}

.list-navigation__column {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 50%;
  line-height: var(--cc-line-height-44);

  &._left {
    justify-content: flex-start;
  }

  &._right {
    margin-left: auto;
    justify-content: flex-end;
  }
}

/* React table ovveride pagination */

.ReactTable {
  .pagination-bottom {
    display: grid;
    grid-template-columns: 1fr 1fr !important;
  }

  .list-navigation__column._right {
    grid-column: 2/3;
  }
}

.pagination-custom {
  min-width: 504px;
  margin: 0;

  .pagination__item {
    display: inline-block;
    margin-right: var(--vds-spacing-4);
    width: 46px;
    height: 44px;
    line-height: var(--cc-line-height-44);
    border: solid 1px var(--cc-color-light-gray-13);
    text-align: center;
    border-radius: var(--vds-round-4);

    &._current {
      background: var(--cc-color-white);
    }

    &._gap {
      cursor: default;
      color: var(--cc-color-blue-6);
    }
  }

  .pagination__link {
    display: block;
    font-size: var(--cc-font-size-18);
    color: var(--cc-color-blue-6);
    text-decoration: none;

    &:hover {
      color: var(--cc-color-blue-7);
      text-decoration: none;
    }

    &._disabled {
      color: var(--cc-color-base-font-color);

      &:hover {
        color: var(--cc-color-base-font-color);
        cursor: default;
      }
    }
  }

  a {
    text-decoration: none;
  }
}
