.auth {
  width: 100%;
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0;

  .login-gradient {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background: linear-gradient(77.49deg, var(--cc-color-blue-15) 0.52%, var(--cc-color-blue-14) 83.61%);
    position: relative;

    .triangle {
      background: linear-gradient(
        to bottom left,
        transparent 0%,
        transparent 50%,
        var(--cc-color-blue-19) 50%,
        var(--cc-color-blue-21) 100%
      );
      width: 90%;
      height: 40%;
      display: flex;
      align-self: flex-end;
    }

    .logo {
      background: url("./images/voisoLogo.svg") no-repeat;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      background-size: contain;
      width: 169px;
      height: 32px;
      position: absolute;
      top: 5%;
      left: 8%;
    }

    .info-block {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      position: absolute;
      bottom: 5%;
      left: 8%;
      color: var(--cc-color-light-gray-11);
      font-size: var(--cc-font-size-18);

      a {
        color: var(--cc-color-light-gray-11);
      }
    }
  }

  .error-message {
    font-size: var(--cc-font-size-14);
    color: var(--cc-color-red-1);
    letter-spacing: 0;
    text-align: right;
    line-height: var(--cc-line-height-20);
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    width: 100%;
    padding: 0 18%;
    position: relative;
    top: -22px;
  }

  .attempts_error {
    display: flex;
    gap: var(--vds-spacing-8);
    border-radius: var(--vds-round-4);
    border: 1px solid var(--vds-red-5);
    width: 65%;
    position: relative;
    background: var(--vds-red-1);
    padding: var(--vds-spacing-12);

    svg {
      min-width: 16px;
    }
  }

  .attempts_error_text {
    font-weight: var(--cc-font-weight-normal);
    font-size: var(--cc-font-size-14);
    line-height: var(--cc-line-height-20);
    text-align: start;
    color: var(--vds-gray-10);
  }

  .box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    background: var(--cc-color-white);
    text-align: left;
    width: 100%;

    .new_user {
      width: 100%;
      height: 100%;
      min-height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .two-factor-auth-hint {
      align-self: flex-start;
      color: var(--vds-gray-7);
      padding-bottom: var(--vds-spacing-12);
      padding-right: 10%;
    }

    .two_factor_sign_in {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .alert {
        width: 65%;
      }
    }

    .login-title {
      font-style: normal;
      font-weight: var(--cc-font-weight-normal);
      font-size: var(--cc-font-size-43);
      line-height: var(--cc-line-height-55);
      color: var(--cc-color-gray-17);
      margin-bottom: 65px;
      width: 65%;
    }

    .field {
      width: 65%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 30px;

      input {
        border-bottom: 1px solid var(--cc-color-gray-18);
        width: 100%;
        outline: none;
        font-weight: var(--cc-font-weight-light);
        font-size: var(--cc-font-size-25);
        line-height: var(--cc-line-height-35);

        &:-webkit-autofill::first-line,
        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
          font-family: Yantramanav, serif;
          font-weight: var(--cc-font-weight-light);
          font-size: var(--cc-font-size-25);
          line-height: var(--cc-line-height-35);
          box-shadow: 0 0 0 1000px var(--cc-color-white) inset; /* trick for chrome/safari */
          filter: none; /* for firefox */
        }
      }

      input[type="password"] {
        font-size: var(--cc-font-size-25);
        letter-spacing: 10px;
      }
    }

    .field__label {
      display: flex;
      align-self: flex-start;
      font-size: var(--cc-font-size-14);
      line-height: var(--cc-line-height-20);
      position: relative;
      top: -3px;
      color: var(--cc-color-gray-7);
    }

    .with-error input {
      border-bottom: 1px solid var(--cc-color-red-1);
    }

    .remember {
      margin-top: 15px;
      line-height: var(--cc-line-height-22);
    }

    .actions {
      width: 65%;
      margin-top: 90px;
    }

    .actions-code {
      margin-top: var(--vds-spacing-32);
    }

    .actions .btn {
      background: var(--cc-color-blue-primary);
      border-radius: var(--vds-round-2);
      width: 100%;
      padding: 0;
      font-size: var(--cc-font-size-20);
      font-weight: var(--cc-font-weight-light);
      line-height: var(--cc-line-height-20);
      border: none;
      height: 54px;
      outline: none;

      &:active {
        box-shadow: none;
      }
    }
  }
}

.hidden {
  display: none !important;
}

a {
  cursor: pointer;
}
