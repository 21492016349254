@define-mixin button {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: var(--cc-color-blue-primary);
  color: var(--cc-color-white);
  border-radius: var(--vds-round-2);
  text-align: center;
  font-weight: var(--cc-font-weight-light);
  height: 30px;
  outline: none;
  padding: 0 var(--vds-spacing-20);
  border: none;
  line-height: var(--cc-line-height-normal);

  &._bold {
    font-weight: var(--cc-font-weight-semibold);
  }

  &.new {
    font-size: var(--cc-font-size-18);
    padding: 0 3rem;
    height: 40px;
  }

  &:hover,
  :active {
    box-shadow: 0 2px 4px 0 var(--cc-color-blue-13);
  }

  &.disabled,
  &._disabled,
  &[disabled] {
    background: var(--cc-color-blue-8);
    background-color: var(--cc-color-blue-8) !important;
    cursor: default;
    color: var(--cc-color-white);

    &:hover {
      box-shadow: none;
    }
  }

  &._inverse {
    background: var(--cc-color-white);
    border: 1px solid var(--cc-color-blue-primary);
    color: var(--cc-color-blue-primary);
    text-shadow: none;

    &:hover {
      background: var(--cc-color-blue-18);
      box-shadow: none;
    }

    &:active {
      background: var(--cc-color-blue-20);
      box-shadow: none;
    }

    &.disabled,
    &._disabled,
    &[disabled] {
      background: var(--cc-color-white);
      color: var(--cc-color-blue-3);
      border: 1px solid var(--cc-color-blue-3);
    }
  }

  &.max120 {
    max-width: 120px;
  }
}

button.button,
a.button,
.button {
  @mixin button;
}
