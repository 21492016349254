@font-face {
  font-family: Yantramanav;
  src: url("./fonts/Yantramanav-Light.woff2") format("woff2");
  font-display: block;
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: Yantramanav;
  src: url("./fonts/Yantramanav-Regular.woff2") format("woff2");
  font-display: block;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: Yantramanav;
  src: url("./fonts/Yantramanav-Medium.woff2") format("woff2");
  font-display: block;
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: Yantramanav;
  src: url("./fonts/Yantramanav-Bold.woff2") format("woff2");
  font-display: block;
  font-style: normal;
  font-weight: 800;
}

body {
  font:
    300 18px/26px Yantramanav,
    Helvetica,
    Noto,
    serif;
}

.font-weight-inherit {
  font-weight: inherit;
}
