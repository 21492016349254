.userProfilePic {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--vds-circle);
  background-color: var(--vds-blue-6);
  font-family: var(--main-font-family), serif;
  font-style: normal;
  font-weight: var(--cc-font-weight-medium);
  font-size: var(--cc-font-size-13);
  line-height: var(--cc-line-height-20);
  color: var(--vds-gray-1);
  text-transform: uppercase;
  cursor: pointer;

  &:hover {
    background-color: var(--vds-blue-7);

    .userProfileMenu {
      display: flex;
    }
  }

  &:active,
  &.active {
    background-color: var(--vds-blue-8);
  }

  &:focus-visible {
    outline: none;
  }

  &:focus-visible::after {
    content: "";
    height: 100%;
    width: 32px;
    border: 1px solid var(--vds-blue-6);
    box-shadow: 0 0 0 4px var(--vds-other-blue-015);
    position: absolute;
  }

  #ui-state {
    position: absolute;
    right: 0;
    bottom: 10px;
    width: 10px;
    height: 10px;
    background-color: var(--vds-gray-1);
    border-radius: var(--vds-circle);
    display: flex;
    align-items: center;
    justify-content: center;

    .status-circle-small {
      width: 8px;
      height: 8px;
      border-radius: var(--vds-circle);
      display: initial;
      position: initial;
      padding: initial;
    }
  }

  .userProfileMenu {
    width: 230px;
    display: flex;
    position: absolute;
    right: 0;
    top: 100%;
    z-index: var(--vds-layer-level-important);
    flex-direction: column;
    background-color: var(--vds-gray-1);
    box-shadow: var(--vds-light-shadow-3);
    border-radius: var(--vds-round-0) var(--vds-round-0) var(--vds-round-4) var(--vds-round-4);
    padding: var(--vds-spacing-8) 0;
    user-select: none;
    text-transform: none;

    li {
      list-style: none;
      color: var(--vds-gray-10);

      &.info {
        padding: var(--vds-spacing-10) var(--vds-spacing-20);

        .name {
          margin-bottom: var(--vds-spacing-4);
        }

        .email {
          font-weight: var(--cc-font-weight-normal);
          color: var(--vds-gray-8);
        }
      }

      &.separator {
        background-color: var(--vds-gray-4);
        height: 1px;
        margin: var(--vds-spacing-8) 0;
        padding: 0;
      }

      &.menuItemLink {
        a {
          display: block;
          padding: var(--vds-spacing-10) var(--vds-spacing-20);
          color: var(--vds-gray-10);

          &:hover {
            background: var(--vds-gray-2);
            color: var(--vds-blue-6);
          }
        }
      }
    }
  }
}

#logout {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .exit-icon {
    background: url("./images/icon-logout.svg") no-repeat 50% 50%;
    background-size: contain;
    width: 18px;
    height: 14px;
  }
}
