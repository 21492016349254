/* stylelint-disable color-named, color-no-hex */
:root {
  --cc-script-colors-option-hover-background: #f4f4f5;
  --cc-script-colors-border: #e8e9ed;
  --cc-script-colors-blue-border: #95b7ef;
  --cc-script-colors-blue-hover: #1b54b1;
  --cc-script-colors-blue-background: #edf3fc;
  --cc-script-colors-gray2: #e9e9ec;
  --cc-script-colors-dtmf-border: #d6d8d9;
  --cc-script-colors-dtmf-key: #4c8df7;
  --cc-script-colors-dtmf1: #6db5bf;
  --cc-script-colors-dtmf2: #a1d177;
  --cc-script-colors-dtmf3: #e6d15b;
  --cc-script-colors-dtmf4: #e6d15b;
  --cc-script-colors-purple: #901fff;
  --cc-script-colors-purple-border: #b870ff;
  --cc-script-colors-purple-hover: #6700cc;
  --cc-script-colors-purple-background: #f5ebff;
}
