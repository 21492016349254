.input-field__label {
  display: block;
  margin: 0;
  font-weight: var(--cc-font-weight-light);
}

.input-field__input {
  display: block;
  width: 100%;
  height: 40px;
  padding: 0 var(--vds-spacing-10);
  outline: 0;
  background-color: var(--cc-color-white);
  border: solid 1px var(--cc-color-light-gray-13);
  border-radius: var(--vds-round-4);
  font-size: var(--cc-font-size-18);
  outline-offset: 0;

  &:hover {
    border-color: var(--cc-color-light-gray-13);
  }

  &:focus {
    border-color: var(--cc-color-blue-primary);
  }

  &:disabled {
    background: var(--cc-color-light-gray-1);
    color: var(--cc-color-gray-11);

    &:not(.input-error):hover {
      border-color: var(--cc-color-light-gray-13);
      cursor: default;
    }
  }

  &._disabled {
    background: var(--cc-color-light-gray-1);
    color: var(--cc-color-gray-11);

    &:not(.input-error):hover {
      border-color: var(--cc-color-light-gray-13);
      cursor: default;
    }
  }

  &._number {
    padding-right: 0;
  }
}
