/* stylelint-disable color-named, color-no-hex */
:root {
  --cc-webrtc-color-btn-green: #2fac83;
  --cc-webrtc-color-green-status: #5bb85b;
  --cc-webrtc-color-green-hover: #0aca8b;
  --cc-webrtc-color-green-active: #119a6d;
  --cc-webrtc-color-white-hover: rgb(255 255 255 / 12%);
  --cc-webrtc-color-white-active: rgb(255 255 255 / 28%);
  --cc-webrtc-color-gray-shadow: rgb(0 0 0 / 24%);
  --cc-webrtc-color-white-notice: rgb(255 255 255 / 20%);
  --cc-webrtc-color-obscure-grey: #767e87;
  --cc-webrtc-color-gray: #8892a3;
  --cc-webrtc-color-light-gray: #99a2b0;
  --cc-webrtc-color-gray-hover: #aab1bd;
  --cc-webrtc-color-transparent-dark-72: rgb(27 39 58 / 72%);
  --cc-webrtc-color-transparent-white-92: rgb(255 255 255 / 92%);
  --cc-webrtc-color-transparent-white-44: rgb(255 255 255 / 44%);
  --cc-webrtc-color-transparent-white-28: rgb(255 255 255 / 28%);
  --cc-webrtc-color-transparent-white-12: rgb(255 255 255 / 12%);
  --cc-webrtc-color-transparent-white-8: rgb(255 255 255 / 8%);
  --cc-webrtc-color-core-blue: #2a3d5a;
  --cc-webrtc-color-dark: rgb(27 39 58 / 100%);
  --cc-webrtc-color-dark-gray: #667388;
  --cc-webrtc-color-dark-28: #1e2c41;
  --cc-webrtc-color-dark-36: #1b273a;
  --cc-webrtc-color-red-default: #ca5868;
  --cc-webrtc-color-red-hover: #de445a;
  --cc-webrtc-color-red-pressed: #c13d4f;
  --cc-webrtc-color-red-unavailable: #ee807f;
  --cc-webrtc-color-gray-text: #778395;
  --cc-webrtc-color-static-gradient: linear-gradient(
    150.57deg,
    #2a3b58 6.89%,
    #244564 29.67%,
    #365368 55.85%,
    #2c445f 78.69%,
    #2d3d56 101.3%
  );
  --cc-webrtc-color-dynamic-gradient: linear-gradient(
    150.57deg,
    #2a3b58 30%,
    #244564 40%,
    #365368 50%,
    #2c445f 60%,
    #2d3d56 70%
  );
  --cc-webrtc-color-folded-gradient: linear-gradient(
    246.48deg,
    #2a3b58 68.8%,
    #244564 100.41%,
    #3c5f78 136.75%,
    #2c445f 168.44%,
    #2d3d56 199.81%
  );
  --cc-webrtc-color-folded-darken: linear-gradient(270deg, #2a3c59 32.19%, rgb(42 60 89 / 0%) 116.2%);
  --cc-webrtc-color-abort: #ca5868;
  --cc-webrtc-color-abort-hover: #de445a;
  --cc-webrtc-color-pale: #55647b;
  --cc-webrtc-color-pale-blue: #ccd0d7;
  --cc-webrtc-color-light-blue: #d7e0ec;
  --cc-webrtc-color-incoming-animation: #d7e0ec;
  --cc-webrtc-color-blue-for-gradient: #2a3b58;
}
