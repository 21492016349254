/* stylelint-disable color-named, color-no-hex */
:root {
  --cc-btn-colors-normal-hover: #c6d9f9;
  --cc-btn-colors-normal-disabled: #a6c6fa;
  --cc-btn-colors-light-hover: #eaf1fe;
  --cc-btn-colors-light-disabled: #a6c6fa;
  --cc-btn-colors-gray-main: #f1f6fb;

  /* --vds-blue-1 */
  --cc-btn-colors-gray-disabled: #eff4fd;
  --cc-btn-colors-red-hover: rgb(244 67 54 / 8%);
  --cc-btn-colors-common-background: #4c8df7;
  --cc-btn-colors-common-hover: rgb(108 170 255 / 58%);
  --cc-btn-colors-common-disabled: #a3c4fa;
  --cc-btn-colors-common-shadow: rgb(108 170 255 / 58%);
  --cc-btn-colors-omni-cancel: #32475a;
  --cc-btn-colors-call-normal: #009688;
  --cc-btn-colors-call-hover: #02ad9d;
  --cc-btn-colors-call-active: #00c3b1;
  --cc-btn-colors-end-call-normal: #e4456e;
  --cc-btn-colors-end-call-hover: #f64c77;
  --cc-btn-colors-end-call-active: #fd5c86;
  --cc-btn-colors-cancel-normal: #838db5;
  --cc-btn-colors-cancel-hover: #8e99c5;
  --cc-btn-colors-cancel-active: #9ba6d5;
}
