/* stylelint-disable color-named, color-no-hex */
:root {
  /* base */
  --cc-color-base-font-color: #454545;
  --cc-color-blue-primary: #3375e0;
  --cc-color-black: black;
  --cc-color-white: #fff;

  /* white */
  --cc-color-white-1: #f3f5f6;
  --cc-color-white-2: #f7f7f7;
  --cc-color-white-3: #f4f6f9;
  --cc-color-white-4: #f5f7f7;
  --cc-color-white-5: #f2f3f7;
  --cc-color-white-6: #f2f5f6;
  --cc-color-white-7: #f6f8f9;
  --cc-color-white-010: rgb(255 255 255 / 10%);

  /* light gray */
  --cc-color-light-gray-1: #f7f9fa;
  --cc-color-light-gray-2: #d3d6d8;
  --cc-color-light-gray-3: #eeeff3;
  --cc-color-light-gray-4: #ced1d1;
  --cc-color-light-gray-5: #cbced9;
  --cc-color-light-gray-6: #ddd;
  --cc-color-light-gray-7: #f2f2f7;
  --cc-color-light-gray-8: rgb(236 240 243 / 100%);
  --cc-color-light-gray-9: #edeef3;
  --cc-color-light-gray-10: #edeef4;
  --cc-color-light-gray-11: #d0d3de;
  --cc-color-light-gray-12: #d5d7d8;
  --cc-color-light-gray-13: #e2e7f4;
  --cc-color-light-gray-14: #f9f2f4;
  --cc-color-light-gray-15: rgb(242 242 242);
  --cc-color-light-gray-16: rgb(242 245 246);
  --cc-color-light-gray-17: rgb(226 231 244);
  --cc-color-light-gray-18: #f1f1f1;
  --cc-color-light-gray-19: rgb(222 225 226 / 100%);

  /* gray */
  --cc-color-gray-1: #a5b5c7;
  --cc-color-gray-2: #99a9ba;
  --cc-color-gray-3: #d4d7d8;
  --cc-color-gray-4: rgb(192 192 192 / 100%);
  --cc-color-gray-5: #8f97a2;
  --cc-color-gray-6: #a0a0a0;
  --cc-color-gray-7: #9b9b9b;
  --cc-color-gray-8: #a2a2a2;
  --cc-color-gray-9: #aaa;
  --cc-color-gray-10: #cacbcc;
  --cc-color-gray-11: #999;
  --cc-color-gray-12: #888;
  --cc-color-gray-13: #727272;
  --cc-color-gray-14: #696970;
  --cc-color-gray-15: rgb(239 239 239 / 30%);
  --cc-color-gray-16: #595f76;
  --cc-color-gray-17: #5d5d5d;
  --cc-color-gray-18: #5e5c69;
  --cc-color-gray-19: rgb(50 62 68 / 90%);
  --cc-color-gray-20: #292a34;
  --cc-color-gray-21: rgb(69 69 69 / 50%);
  --cc-color-gray-22: #acadc4;

  /* black */
  --cc-color-black-1: #030303;
  --cc-color-black-015: rgb(0 0 0 / 15%);
  --cc-color-black-040: rgb(0 0 0 / 40%);
  --cc-color-black-05: rgb(0 0 0 / 5%);
  --cc-color-black-08: rgb(0 0 0 / 8%);

  /* blue */
  --cc-color-blue-1: #d3d5e1;
  --cc-color-blue-2: #c1cecf;
  --cc-color-blue-3: #c0d9e8;
  --cc-color-blue-4: #66a4c9;
  --cc-color-blue-5: #77c1ee;
  --cc-color-blue-6: #579fcb;
  --cc-color-blue-7: #77c1ee;
  --cc-color-blue-8: #a3c4fa;
  --cc-color-blue-9: #6da6f3;
  --cc-color-blue-10: #3f92c4;
  --cc-color-blue-11: #4b8df7;
  --cc-color-blue-12: #5893f7;
  --cc-color-blue-13: rgb(108 170 255 / 58%);
  --cc-color-blue-14: #8392bf;
  --cc-color-blue-15: #515a7e;
  --cc-color-blue-16: #4c4e65;
  --cc-color-blue-17: rgb(76 78 101 / 80%);
  --cc-color-blue-18: rgb(76 141 247 / 10%);
  --cc-color-blue-19: rgb(81 90 126 / 12%);
  --cc-color-blue-20: rgb(76 141 247 / 20%);
  --cc-color-blue-21: rgb(81 90 126 / 30.2%);
  --cc-color-blue-22: #121a42;
  --cc-color-blue-23: #86bff9;

  /* red */
  --cc-color-red-1: #ff787b;
  --cc-color-red-2: #e85454;
  --cc-color-red-3: #f44336;
  --cc-color-red-4: #d83939;
  --cc-color-red-5: #f00;
  --cc-color-red-6: #bc3c2e;
  --cc-color-red-7: rgb(199 36 33);
  --cc-color-red-8: #c7254e;
  --cc-color-red-9: #c72421;
  --cc-color-red-10: #950808;

  /* green */
  --cc-color-green-1: #8cccb2;
  --cc-color-green-2: #7ab786;
  --cc-color-green-3: #323e44;

  /* yellow */
  --cc-color-yellow-1: #ffc439;
}
