:root {
  --cc-icon-base-edit-grey: url("./edit_grey.svg");
  --cc-icon-base-edit-blue: url("./edit_blue.svg");
  --cc-icon-pdf-file: url("./file-pdf-grey.svg");
  --cc-icon-error-circle: url("./error-circle.svg");
  --cc-icon-success-circle: url("./success-circle.svg");
  --cc-icon-jcb: url("./jcb.svg");
  --cc-icon-mastercard: url("./mastercard.svg");
  --cc-icon-visa: url("./visa.svg");
  --cc-icon-cards: url("./cards.svg");
  --cc-icon-history: url("./history.svg");
  --cc-icon-invoices: url("./invoices.svg");
  --cc-icon-prepaid-invoices: url("./prepaid-invoices.svg");
  --cc-icon-usage: url("./usage.svg");
  --cc-icon-recordings: url("./recordings.svg");
  --cc-icon-secured-payments: url("./secured-payments.svg");
  --cc-icon-drag: url("./drag.svg");
  --cc-icon-drag-hover: url("./drag-hover.svg");
  --cc-icon-delete-icon: url("./delete.svg");
  --cc-icon-delete-icon-blue: url("./delete-blue.svg");
  --cc-icon-card-delete-success: url("./card-delete-success.svg");
  --cc-icon-circle-red-attention: url("./circle-red-attention.svg");
  --cc-icon-visa-card: url("./visa-card.svg");
  --cc-icon-master-card-card: url("./master-card-card.svg");
  --cc-icon-jcb-card: url("./jcb-card.svg");
  --cc-icon-american-express-card: url("./american-express-card.svg");
  --cc-icon-cartes-bancaires: url("./cartes_bancaires.svg");
  --cc-icon-discover: url("./discover.svg");
  --cc-icon-unionpay: url("./unionpay.svg");
  --cc-icon-interac: url("./interac.svg");
  --cc-icon-dragged-background: url("./dragged-background.svg");
  --cc-icon-low-balance-alert-off-hover: url("./lowBalanceAlertOff-hover.svg");
  --cc-icon-low-balance-alert-off: url("./lowBalanceAlertOff.svg");
  --cc-icon-low-balance-alert-on: url("./lowBalanceAlertOn.svg");
  --cc-icon-paypal: url("./paypal.svg");
  --cc-icon-arrow-up-icon: url("./up-arrow-x1.svg");
  --cc-icon-arrow-down-icon: url("./dropdown-arrow-x1.svg");
  --cc-icon-close: url("./close.svg");
  --cc-icon-close-outlined: url("./close-outlined.svg");
  --cc-icon-minus: url("./minus-icon.svg");
  --cc-icon-info: url("./info-icon.svg");
  --cc-icon-clear: url("./clear.svg");
  --cc-icon-copy-grey: url("./copy_grey.svg");
  --cc-icon-checked: url("./checked.svg");
  --cc-icon-warning: url("./icon-warning.svg");
  --cc-icon-warning-outlined-grey: url("./warning-outlined-grey.svg");
  --cc-icon-close-grey: url("./close_grey.svg");
  --cc-icon-skills-handler: url("./skills-handler.svg");
  --cc-icon-csv-file-grey: url("./file-csv-grey.svg");
  --cc-icon-csv-file-blue: url("./file-csv-blue.svg");
  --cc-icon-more-horizontal-grey: url("./more-horizontal-grey.svg");
  --cc-icon-arrow-right-outlined-blue: url("./arrow-right-outlined-blue.svg");
  --cc-icon-arrow-down-grey: url("./arrow-down-grey.svg");
  --cc-icon-arrow-up-blue: url("./arrow-up-blue.svg");
  --cc-icon-arrow-right-grey: url("./arrow-right-grey.svg");
  --cc-icon-checked-thin: url("./checked-thin.svg");
  --cc-icon-checked-ultra-thin: url("./checked-ultra-thin.svg");
  --cc-icon-loading-white: url("./loading-white.svg");
  --cc-icon-calendar-blue: url("./calendar-blue.svg");
  --cc-icon-calendar-light-grey: url("./calendar-light-grey.svg");
  --cc-icon-arrow-down-thin-dark-grey: url("./arrow-down-thin-dark-grey.svg");
  --cc-icon-arrow-down-white: url("./arrow-down-white.svg");
  --cc-icon-close-white: url("./close-white.svg");
  --cc-icon-search-grey: url("./search-grey.svg");
  --cc-icon-search-light-grey: url("./search-light-grey.svg");
  --cc-icon-search-thin-light-grey: url("./search-thin-light-grey.svg");
  --cc-icon-arrow-down-grey-transparent: url("./arrow-down-grey-transparent.svg");
  --cc-icon-radio-button-on: url("./radio-btn-on.svg");
  --cc-icon-radio-button-off: url("./radio-btn-off.svg");
  --cc-icon-radio-disabled: url("./radio-disabled.svg");
  --cc-icon-radio-empty: url("./radio-empty.svg");
  --cc-icon-radio-fill: url("./radio-fill.svg");
  --cc-icon-radio-hover: url("./radio-hover.svg");
  --cc-icon-close-grey-rounded: url("./close-grey-rounded.svg");
  --cc-icon-close-red-rounded: url("./close-red-rounded.svg");
  --cc-icon-checkbox-on: url("./checkbox-on.svg");
  --cc-icon-checkbox-off: url("./checkbox-off.svg");
  --cc-icon-voiso-logo: url("./voiso-logo.svg");
  --cc-icon-expand-icon: url("./expand-arrow.svg");
  --cc-icon-expand-icon-active: url("./expand-arrow-active.svg");
  --cc-icon-expand-icon-blue: url("./expand-arrow-blue.svg");
  --cc-icon-audio-download: url("./audio/download.svg");
  --cc-icon-audio-play: url("./audio/play.svg");
  --cc-icon-audio-stop: url("./audio/stop.svg");
  --cc-icon-cdr-sms-fail: url("./cdr/sms-fail.svg");
  --cc-icon-cdr-sms-success: url("./cdr/sms-success.svg");
  --cc-icon-transcription-arrow: url("./transcription/arrow-icon.svg");
  --cc-icon-transcription-checked: url("./transcription/checked-icon.svg");
  --cc-icon-transcription-keywords: url("./transcription/keywords-icon.svg");
  --cc-icon-transcription-keywords-active: url("./transcription/keywords-active-icon.svg");
  --cc-icon-transcription-messages: url("./transcription/messages-icon.svg");
  --cc-icon-transcription-messages-active: url("./transcription/messages-active-icon.svg");
  --cc-icon-table-paginator-arrow-back-hover: url("./tablePaginator/arrow-back-hover.svg");
  --cc-icon-table-paginator-arrow-back: url("./tablePaginator/arrow-back.svg");
  --cc-icon-table-paginator-arrow-forward-hover: url("./tablePaginator/arrow-forward-hover.svg");
  --cc-icon-table-paginator-arrow-forward: url("./tablePaginator/arrow-forward.svg");
  --cc-icon-table-paginator-double-arrow-back-hover: url("./tablePaginator/double-arrow-back-hover.svg");
  --cc-icon-table-paginator-double-arrow-back: url("./tablePaginator/double-arrow-back.svg");
  --cc-icon-table-paginator-double-arrow-forward-hover: url("./tablePaginator/double-arrow-forward-hover.svg");
  --cc-icon-table-paginator-double-arrow-forward: url("./tablePaginator/double-arrow-forward.svg");
}
