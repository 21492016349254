:root {
  --cc-font-size-8: 8px;
  --cc-font-size-9: 9px;
  --cc-font-size-10: 10px;
  --cc-font-size-11: 11px;
  --cc-font-size-12: 12px;
  --cc-font-size-13: 13px;
  --cc-font-size-14: 14px;
  --cc-font-size-15: 15px;
  --cc-font-size-16: 16px;
  --cc-font-size-17: 17px;
  --cc-font-size-18: 18px;
  --cc-font-size-20: 20px;
  --cc-font-size-22: 22px;
  --cc-font-size-24: 24px;
  --cc-font-size-25: 25px;
  --cc-font-size-28: 28px;
  --cc-font-size-30: 30px;
  --cc-font-size-35: 35px;
  --cc-font-size-40: 40px;
  --cc-font-size-41: 41px;
  --cc-font-size-43: 43px;
  --cc-font-size-52: 52px;
  --cc-font-size-54: 54px;
  --cc-font-size-100: 100px;
  --cc-font-size-142: 142px;
}
