@keyframes loading {
  100% {
    transform: translateX(100%);
  }
}

.dropdown-clipped {
  clip-path: inset(0 -100% -100% -100%);
}

header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-height: 55px;
  position: relative;
  border-bottom: 1px solid var(--vds-gray-4);
  background-color: var(--vds-gray-1);
  font-family: var(--main-font-family), sans-serif;
  font-style: normal;
  font-weight: var(--cc-font-weight-normal);
  font-size: var(--cc-font-size-14);
  line-height: var(--cc-line-height-20);
  letter-spacing: 0;

  ul {
    margin: 0;
  }

  .userbar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0 var(--vds-spacing-20);
  }

  .inner {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1240px;
  }

  .menu {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    min-height: 55px;

    .logo {
      display: flex;
      align-items: center;
      height: 24px;
      min-width: 122px;

      img {
        object-fit: scale-down;
        max-height: 100%;
        max-width: 100%;
        height: 100%;
      }
    }

    .main-menu {
      display: grid;
      grid-auto-flow: column;
      gap: var(--vds-spacing-24);
      margin-left: var(--vds-spacing-32);
      height: 55px;

      .dropdown {
        cursor: pointer;
        position: relative;
        color: var(--vds-gray-10);

        &::before {
          content: "";
          width: 100%;
          height: 1px;
          bottom: -1px;
          position: absolute;
        }

        &._current {
          color: var(--vds-blue-6);

          &::after {
            content: "";
            height: 1px;
            background-color: var(--vds-blue-6);
            position: absolute;
            bottom: -1px;
            left: 0;
            right: 0;
          }
        }

        &:hover {
          .dropdown-menu-list {
            display: flex;
          }

          color: var(--vds-blue-6);
        }
      }
    }

    .dropdown-menu-list {
      display: none;
      position: absolute;
      top: calc(100% + 1px);
      left: -20px;
      text-align: left;
      list-style: none;
      flex-direction: column;
      width: 230px;
      min-width: 230px;
      padding: var(--vds-spacing-8) 0;
      background: var(--vds-gray-1);
      z-index: var(--vds-layer-level-important);
      border-bottom-left-radius: var(--vds-round-4);
      box-shadow: var(--vds-light-shadow-3);
      border-bottom-right-radius: var(--vds-round-4);

      li {
        padding: 0;
        height: auto;
        width: 100%;
        list-style: none;
        color: var(--vds-gray-10);
        font-weight: var(--cc-font-weight-normal);

        &._current {
          background-color: var(--vds-blue-1);
          color: var(--vds-blue-6);
        }

        &:hover:not(._current) {
          background-color: var(--vds-gray-2);
          color: var(--vds-blue-6);
        }
      }

      a {
        padding: var(--vds-spacing-10) var(--vds-spacing-10) var(--vds-spacing-10) var(--vds-spacing-20);
        font-weight: var(--cc-font-weight-normal);
        color: inherit;
        width: 100%;

        &:active {
          background-color: var(--vds-gray-3);
        }
      }
    }

    li {
      display: flex;

      p {
        margin: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.separator {
  min-width: 1px;
  height: 24px;
  background-color: var(--vds-gray-4);
  padding: 0;

  &.burger-menu {
    display: none;
    margin-left: var(--vds-spacing-20);
  }
}

#user-profile-tooltip {
  position: absolute;
  inset: 0;
  z-index: var(--vds-layer-level-2);
}

.user-navbar {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  min-height: 55px;
  gap: var(--vds-spacing-20);

  & > li {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }

  .user-profile {
    align-self: stretch;
    position: relative;
  }

  .profile-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-self: stretch;
    align-items: center;
    cursor: pointer;
    position: relative;

    & > div {
      height: 100%;
      width: 100%;

      & > div {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
      }
    }

    &:hover {
      background-color: rgb(255 255 255 / 15%);

      .feature-button {
        background-color: var(--vds-blue-3);
      }

      .icon {
        background-color: var(--vds-blue-7);
      }

      .feature-icon {
        color: var(--vds-blue-7);
      }
    }

    .feature-button {
      width: 32px;
      height: 32px;
      border-radius: var(--vds-circle);
      background-color: var(--vds-blue-2);
      display: flex;
      align-items: center;
      justify-content: center;

      .feature-icon {
        color: var(--vds-blue-6);
      }

      &:focus {
        outline: none;
      }

      &:focus-visible {
        outline: none;

        &::after {
          content: "";
          height: 100%;
          width: 32px;
          border: 1px solid var(--vds-blue-6);
          box-shadow: 0 0 0 4px var(--vds-other-blue-015);
          position: absolute;
        }
      }

      .icon {
        background-color: var(--vds-blue-6);
      }
    }

    &:active {
      .feature-button {
        background-color: var(--vds-blue-4);
      }

      .icon {
        background-color: var(--vds-blue-8);
      }

      .feature-icon {
        color: var(--vds-blue-8);
      }
    }
  }

  .time {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    border: none;
    position: relative;
    transition: 0.2s opacity ease-in-out;
    padding-left: var(--vds-spacing-20);

    &.hiding {
      opacity: 0;
    }

    .skeleton-line {
      position: relative;
      overflow: hidden;
      background-color: var(--vds-gray-2);
      width: 80px;
      height: 12px;

      &:last-child {
        width: 120px;
      }

      &::after {
        display: block;
        content: "";
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        transform: translateX(-100%);
        animation: loading 2s infinite;
        background: linear-gradient(
          90deg,
          rgb(246 246 251 / 0%),
          rgb(246 246 251 / 50%) 50%,
          rgb(246 246 251 / 0%) 10%
        );
      }
    }

    /* stylelint-disable selector-id-pattern */

    #current_time {
      /* stylelint-enable selector-id-pattern */
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      gap: var(--vds-spacing-4);

      .current-time-wrapper {
        display: grid;
        width: 100%;
        max-width: 100%;
        justify-content: end;
        justify-items: end;
        align-items: center;
        grid-template-rows: repeat(2, 16px);
        overflow: hidden;
        text-overflow: ellipsis;
        color: var(--vds-gray-8);
        font-weight: var(--cc-font-weight-normal);
        padding-left: var(--vds-spacing-20);

        * {
          white-space: nowrap;
        }
      }

      .current-time-wrapper--single {
        grid-template-rows: 16px;
      }
    }
  }

  .profile,
  #profile {
    line-height: var(--cc-line-height-16);
    font-size: var(--cc-font-size-14);
    max-width: 87px;
    min-width: 87px;
    word-break: break-all;
    padding-right: 15px;
  }
}

._current {
  color: var(--vds-blue-6);
}

.navbar-admin-menu:checked ~ .admin-navbar {
  display: flex;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.navbar-admin-menu:checked + .admin-checkbox-opened {
  .hamburger1 {
    transform: translate3d(0, 0, 0) rotate(45deg);
  }

  .hamburger2 {
    transform: translate3d(0, 0, 0) scale(0.1, 1);
  }

  .hamburger3 {
    transform: translate3d(0, 0, 0) rotate(-45deg);
  }
}

.navbar-mobile-menu:checked ~ .mobile-menu {
  display: flex;
}

.navbar-mobile-menu:checked + .mobile-checkbox-opened {
  .hamburger1 {
    transform: translate3d(0, 0, 0) rotate(45deg);
  }

  .hamburger2 {
    transform: translate3d(0, 0, 0) scale(0, 0);
  }

  .hamburger3 {
    transform: translate3d(0, -1px, 0) rotate(-45deg);
  }
}

ul.admin-navbar {
  display: none;
  flex-direction: column;
  position: absolute;
  margin-left: var(--vds-spacing-20);
  right: calc(-200px + 92px + 20px);
  top: 55px;
  background-color: var(--cc-color-gray-16);
  color: var(--cc-color-white);
  list-style-type: none;
  border-top: 1px solid var(--cc-color-blue-17);
  z-index: 40;

  li {
    a {
      color: var(--cc-color-white);
      height: 40px;
      padding: var(--vds-spacing-10);
      width: 200px;
      cursor: pointer;
      display: flex;

      &:hover {
        background-color: var(--cc-color-white-010);
      }
    }
  }
}

@media only screen and (min-width: 1px) and (max-width: 1260px) {
  header .userbar ul.admin-navbar {
    top: 38px;
    left: 88px;
    right: none;
  }

  .users__crop {
    width: 195px;
  }

  .common-table__cell {
    padding: 13px var(--vds-spacing-10) var(--vds-spacing-10) !important;
  }

  .callcenter-settings__side {
    min-width: 300px;
  }

  .script-panel__body._wide2 {
    padding: var(--vds-spacing-40) 5px;
  }

  header .userbar .inner .menu .main-menu > li:not(.admin) {
    display: none;
  }
}
