:root {
  --cc-line-height-normal: normal;
  --cc-line-height-13: 13px;
  --cc-line-height-14: 14px;
  --cc-line-height-15: 15px;
  --cc-line-height-16: 16px;
  --cc-line-height-17: 17px;
  --cc-line-height-18: 18px;
  --cc-line-height-19: 19px;
  --cc-line-height-20: 20px;
  --cc-line-height-21: 21px;
  --cc-line-height-22: 22px;
  --cc-line-height-23: 23px;
  --cc-line-height-24: 24px;
  --cc-line-height-25: 25px;
  --cc-line-height-26: 26px;
  --cc-line-height-28: 28px;
  --cc-line-height-30: 30px;
  --cc-line-height-31: 31px;
  --cc-line-height-32: 32px;
  --cc-line-height-33: 33px;
  --cc-line-height-35: 35px;
  --cc-line-height-36: 36px;
  --cc-line-height-39: 39px;
  --cc-line-height-40: 40px;
  --cc-line-height-44: 44px;
  --cc-line-height-48: 48px;
  --cc-line-height-49: 49px;
  --cc-line-height-50: 50px;
  --cc-line-height-55: 55px;
  --cc-line-height-58: 58px;
  --cc-line-height-60: 60px;
  --cc-line-height-68: 68px;
  --cc-line-height-70: 70px;
  --cc-line-height-130: 130px;
}
