/* stylelint-disable color-named, color-no-hex */
:root {
  --cc-omni-colors-skeleton-color: #f0f0f1;
  --cc-omni-colors-gray-border: #e9e9ec;
  --cc-omni-colors-pale-blue: #81909f;
  --cc-omni-colors-card-border: #e8e9ed;
  --cc-omni-colors-steel-blue: #507eb3;
  --cc-omni-colors-shark: rgba(33 35 44 / 10%);
  --cc-omni-colors-picton-blue: #269fed;
  --cc-omni-colors-light-green: #e1f6cb;
}
