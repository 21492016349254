@define-mixin paginator {
  .list-navigation {
    margin: var(--vds-spacing-20) 0;
    padding-left: var(--vds-spacing-20);
    padding-right: var(--vds-spacing-20);
    align-items: center;

    .list-navigation__column {
      line-height: 1;
    }

    .pagination__item {
      width: 36px;
      height: 36px;
      line-height: var(--cc-line-height-35);
      margin-right: var(--vds-spacing-6);
      vertical-align: bottom;

      a {
        font-size: var(--cc-font-size-14);
        color: var(--cc-color-blue-primary);
      }

      &.current {
        border-color: var(--cc-color-blue-primary);
        background: var(--cc-color-blue-primary);

        a {
          color: var(--cc-color-white);
        }
      }
    }

    .per-page {
      font-weight: var(--cc-font-weight-normal);

      a {
        color: var(--cc-color-gray-1);
        font-weight: var(--cc-font-weight-normal);
        margin: 0;

        &.disabled {
          color: var(--cc-color-blue-primary);
        }
      }
    }
  }
}

.paginator-block {
  @mixin paginator;

  .pagination__item {
    background: var(--cc-color-white);
  }

  .list-navigation {
    padding-left: 0;
  }
}

section {
  .title {
    padding: 0 var(--vds-spacing-20);
    height: calc(20px * 3);
    border-bottom: 1px solid var(--cc-color-light-gray-13);
    font-size: var(--cc-font-size-20);
    font-weight: var(--cc-font-weight-normal);
    line-height: 1;
    display: flex;
    align-items: center;

    a {
      font-size: var(--cc-font-size-18);
      color: var(--cc-color-blue-primary);
    }

    .count {
      background: var(--cc-color-blue-primary);
      padding: var(--vds-spacing-4);
      padding-top: 5px;
      padding-left: 5px;
      font-size: var(--cc-font-size-12);
      margin-left: 5px;
      border-radius: var(--vds-round-2);
      text-align: center;
      min-width: 22px;
      color: var(--vds-gray-1);
    }

    &.tabs {
      align-items: stretch;
      padding: 0;

      & > div {
        display: flex;
        padding: 0 var(--vds-spacing-20);
        align-items: center;
        border-bottom: 2px solid transparent;
        margin-bottom: -1px;
        cursor: pointer;

        &.active {
          border-color: var(--cc-color-blue-primary);
          cursor: default;
        }
      }
    }
  }

  .tab-content {
    display: none;

    &.active {
      display: block;
    }
  }

  .content {
    padding: var(--vds-spacing-20);
    display: flex;
    align-content: flex-start;
    flex-wrap: wrap;
    width: 100%;

    .option {
      flex-basis: 25%;
      max-width: 25%;

      &.error {
        div,
        span {
          color: var(--cc-color-red-9);
        }

        input {
          border: solid 1px var(--cc-color-red-9);
          color: var(--cc-color-red-9);
        }

        .error-value {
          position: absolute;
          font-size: var(--cc-font-size-14);
          line-height: var(--cc-line-height-18);
          margin-top: var(--vds-spacing-4);
        }
      }

      & + .option {
        padding-left: var(--vds-spacing-20);
      }

      &.full {
        width: 100%;
        flex-basis: 100%;
        max-width: 100%;
        padding-left: 0;
        margin-top: var(--vds-spacing-20);
      }

      &.auto {
        width: auto;
        flex-basis: auto;
      }

      .option-name {
        font-size: var(--cc-font-size-14);
        color: var(--cc-color-gray-11);
        margin-bottom: var(--vds-spacing-4);
      }
    }

    & + .content,
    & + .scroll,
    & + .scroll-v {
      border-top: 1px solid var(--cc-color-light-gray-13);
    }
  }

  .scroll,
  .scroll-v {
    height: 100%;
    overflow: auto;

    &.new-table {
      height: auto;
    }

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: var(--cc-color-light-gray-16);
    }

    &::-webkit-scrollbar-thumb {
      background: var(--cc-color-blue-primary);
    }
  }

  &.default + &.default {
    margin-top: var(--vds-spacing-20);
  }

  &.default {
    background: var(--vds-gray-1);
    border: 1px solid var(--cc-color-light-gray-13);
    border-radius: var(--vds-round-4);

    .common-table__cell {
      padding: 15px var(--vds-spacing-20) !important;

      &._head {
        border: none;
      }

      &:first-of-type {
        border-left: none;
        text-align: left;
      }

      &:last-of-type {
        border-right: none;
        text-align: right;
      }
    }

    .new-table {
      thead {
        border-left: none;
        border-right: none;
      }

      td {
        &:first-of-type {
          border-left: none;
        }

        &:last-of-type {
          border-right: none;
        }
      }
    }

    @mixin paginator;
  } /* default */

  &.seperated {
    background: transparent;
    border: none;
    border-radius: var(--vds-round-0);

    .title {
      padding: 0;
      height: calc(20px * 4);
      border-bottom: none;
      font-size: var(--cc-font-size-35);
      font-weight: var(--cc-font-weight-light);
      line-height: 1;
    }

    .content {
      border: 1px solid var(--cc-color-light-gray-13);
      background: var(--vds-gray-1);
      border-radius: var(--vds-round-4);
    }
  } /* seperated */
}

.download-csv {
  font-weight: var(--cc-font-weight-normal);
  color: var(--cc-color-blue-primary);

  &.disabled {
    opacity: 0.3;
  }

  &::before {
    content: "";
    display: inline-block;
    width: 16px;
    height: 21px;
    margin-right: var(--vds-spacing-6);
    vertical-align: middle;
    background: url("./images/csv-download.svg") no-repeat;
  }

  &:hover {
    &::before {
      opacity: 0.8;
    }
  }

  &.right {
    margin-left: auto;
  }
}

.pending-wrap {
  margin-top: 30px;
  padding: 50px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: var(--cc-color-blue-primary);

  .pending {
    height: 66px;
    width: 66px;
    margin-bottom: var(--vds-spacing-20);
    background: url("./images/pending.svg") no-repeat;
    animation: rotate-360 1s linear infinite;
  }
}

/* restricting too long lists of teams in historical reports */
.content.scroll {
  max-height: 1325px;
}

@keyframes rotate-360 {
  100% {
    transform: rotate(360deg);
  }
}

.hardcap-extensions__toolbar {
  margin-bottom: 18px;
}
