.input-checkbox {
  --size: 19px;

  display: inline-flex;
  position: relative;
  cursor: pointer;
  margin: 0;
  align-items: flex-end;
  font-weight: var(--cc-font-weight-light);
  line-height: 1;

  & > input[type="checkbox"] {
    position: absolute;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    z-index: var(--vds-layer-level-0);

    & + i {
      width: var(--size);
      height: var(--size);
      min-width: var(--size);
      min-height: var(--size);
      border: 1px solid var(--cc-color-base-font-color);
      opacity: 0.5;
      border-radius: 1px;
      background: var(--cc-color-white);
      margin-right: var(--vds-spacing-10);
      background-position: center;

      &:hover {
        cursor: pointer;
        border: 1px solid var(--cc-color-blue-primary);
        opacity: 1;
      }
    }

    &::after {
      position: absolute;
      display: block;
      overflow: hidden;
      width: 0;
      height: 0;
      content: url("./images/check_mark.svg");
    }
  }

  & + .input-checkbox {
    margin-top: var(--vds-spacing-12);
  }

  &.flex {
    display: flex;
    width: 100%;
  }

  &.up {
    align-items: flex-start;

    & > input[type="checkbox"] {
      i {
        margin-top: 0;
      }
    }
  }

  & > input:checked + i {
    background: url("./images/check_mark.svg") no-repeat;
    background-size: 50%;
    background-color: var(--cc-color-blue-primary);
    border-color: var(--cc-color-blue-primary);
    background-position: center;
    opacity: 1;
  }

  &.input-new-style {
    & > input[type="checkbox"] {
      & + i {
        border: 1px solid var(--cc-color-gray-8);
        border-radius: var(--vds-round-2);
        cursor: pointer;
      }
    }

    & > input:checked + i {
      background: url("./images/new_check-mark.svg") no-repeat;
      background-color: var(--cc-color-blue-primary);
      border-color: var(--cc-color-blue-primary);
      background-position: center;
      opacity: 1;
    }

    & > input:indeterminate + i {
      border-color: var(--cc-color-blue-primary);
    }
  }

  & > input:indeterminate + i,
  & > input[indeterminate="true"] + i {
    background: url("./images/minus-icon.svg") no-repeat;
    background-position: center;
    background-size: 55%;
    background-color: var(--cc-color-blue-primary);
    border-color: var(--cc-color-blue-primary);
    opacity: 1;
  }

  & > input:disabled {
    & + i {
      cursor: not-allowed;
      z-index: var(--vds-layer-level-1);
      background: var(--cc-color-blue-1);
      border-color: var(--cc-color-blue-1);
      filter: grayscale(100%);
      opacity: 0.6;

      &::before {
        content: "";
        width: 100%;
        position: absolute;
        display: block;
        height: 100%;
        top: 0;
        cursor: not-allowed;
      }
    }

    &:checked + i {
      background: url("./images/check_mark.svg"), var(--cc-color-blue-1);
      border-color: var(--cc-color-blue-1);
      background-repeat: no-repeat;
      background-size: 56%;
      background-position: center;
    }

    &:indeterminate + i {
      background: url("./images/minus-icon.svg"), var(--cc-color-blue-1);
      border-color: var(--cc-color-blue-1);
      background-repeat: no-repeat;
      background-size: 56%;
      opacity: 1;
      background-position: center;
    }
  }

  &._bold .input-checkbox__caption {
    font-weight: var(--cc-font-weight-normal);
  }

  .ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.input-checkbox__caption {
  display: block;
  position: relative;
  margin-left: 23px;
  font-weight: var(--cc-font-weight-light);
  cursor: pointer;
  line-height: var(--size);
}

.input-checkbox__description {
  line-height: 1.2;
  margin-top: 7px;
  font-size: var(--cc-font-size-14);
  color: var(--cc-color-gray-11);
}

.input-checkbox__text {
  .input-checkbox__caption {
    margin: 0;
  }
}

.checkbox-hoverable {
  width: 100%;
  position: relative;

  & > label {
    padding: var(--vds-spacing-10);
    width: 100%;

    &:hover {
      background: var(--cc-color-light-gray-8);
    }
  }
}

.caption {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
}
