/* stylelint-disable scale-unlimited/declaration-strict-value, color-named, color-no-hex */

@font-face {
  font-family: "Glyphicons Halflings";
  src: url("./fonts/glyphicons-halflings-regular.woff2") format("woff2");
}

.glyphicon {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: "Glyphicons Halflings", sans-serif;
  font-style: normal;
  font-weight: var(--cc-font-weight-normal);
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.glyphicon-search::before {
  content: "\e003";
}

.glyphicon-trash::before {
  content: "\e020";
}

.glyphicon-plus::before {
  content: "\002b";
}

.glyphicon-edit::before {
  content: "\e065";
}

*::before,
*::after {
  box-sizing: border-box;
}

button,
input,
optgroup,
select,
textarea {
  font: inherit;
  color: inherit;
  margin: 0;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: inherit;
  font-weight: var(--cc-font-weight-medium);
  line-height: 1.1;
  color: inherit;
}

p {
  margin: 0 0 10px;
}

.form-group {
  margin-bottom: 15px;
}

.form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: var(--cc-font-size-14);
  line-height: 1.4286;
  color: #555;
  background-color: var(--vds-gray-1);
  background-image: none;
  border: 1px solid #ccc;
  border-radius: var(--vds-round-4);
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 7.5%);
  transition:
    border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s;
}

.form-control:focus {
  border-color: #66afe9;
  outline: 0;
  box-shadow:
    inset 0 1px 1px rgb(0 0 0 / 7.5%),
    0 0 8px rgb(102 175 233 / 60%);
}

.form-control::placeholder {
  color: var(--cc-color-gray-11);
  opacity: 1;
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  background-color: #eee;
  opacity: 1;
}

.form-control[disabled],
fieldset[disabled] .form-control {
  cursor: not-allowed;
}

textarea.form-control {
  height: auto;
}

.btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: var(--cc-font-weight-normal);
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  padding: 6px 12px;
  font-size: var(--cc-font-size-14);
  line-height: 1.4286;
  border-radius: var(--vds-round-4);
  user-select: none;
}

.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.btn:hover,
.btn:focus,
.btn.focus {
  color: #333;
  text-decoration: none;
}

.btn:active,
.btn.active {
  background-image: none;
  outline: 0;
  box-shadow: inset 0 3px 5px rgb(0 0 0 / 12.5%);
}

.btn-success {
  color: var(--vds-gray-1);
  background-color: #5cb85c;
  border-color: #4cae4c;
}

.btn-success:focus,
.btn-success.focus {
  color: var(--vds-gray-1);
  background-color: #449d44;
  border-color: #255625;
}

.btn-success:hover {
  color: var(--vds-gray-1);
  background-color: #449d44;
  border-color: #398439;
}

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
}

.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  padding: 8px;
  line-height: 1.4286;
  vertical-align: top;
  border-top: 1px solid var(--cc-color-light-gray-6);
}

.table > thead > tr > th {
  vertical-align: bottom;
  border-bottom: 2px solid var(--cc-color-light-gray-6);
}

.table > caption + thead > tr:first-child > th,
.table > colgroup + thead > tr:first-child > th,
.table > thead:first-child > tr:first-child > th,
.table > caption + thead > tr:first-child > td,
.table > colgroup + thead > tr:first-child > td,
.table > thead:first-child > tr:first-child > td {
  border-top: 0;
}

.table > tbody + tbody {
  border-top: 2px solid var(--cc-color-light-gray-6);
}

.table .table {
  background-color: var(--vds-gray-1);
}

th {
  text-align: left;
}

.text-right {
  text-align: right;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  background-color: transparent;
}

.label {
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  font-weight: var(--cc-font-weight-bold);
  line-height: 1;
  color: var(--vds-gray-1);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
}

.label-success {
  background-color: #5cb85c;
}

.label-success[href]:hover,
.label-success[href]:focus {
  background-color: #449d44;
}

.label-info {
  background-color: #5bc0de;
}

.label-info[href]:hover,
.label-info[href]:focus {
  background-color: #31b0d5;
}

.label-warning {
  background-color: #f0ad4e;
}

.label-warning[href]:hover,
.label-warning[href]:focus {
  background-color: #ec971f;
}

.btn-warning {
  color: var(--vds-gray-1);
  background-color: #f0ad4e;
  border-color: #eea236;
}

.btn-warning:focus,
.btn-warning.focus {
  color: var(--vds-gray-1);
  background-color: #ec971f;
  border-color: #985f0d;
}

.btn-warning:hover {
  color: var(--vds-gray-1);
  background-color: #ec971f;
  border-color: #d58512;
}

.label-danger {
  background-color: #d9534f;
}

.label-danger[href]:hover,
.label-danger[href]:focus {
  background-color: #c9302c;
}

.label-default {
  background-color: #777;
}

.label-default[href]:hover,
.label-default[href]:focus {
  background-color: #5e5e5e;
}

.btn-default {
  color: #333;
  background-color: var(--vds-gray-1);
  border-color: #ccc;
}

.btn-default:focus,
.btn-default.focus {
  color: #333;
  background-color: #e6e6e6;
  border-color: #8c8c8c;
}

.btn-default:hover {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}

.btn-primary {
  color: var(--vds-gray-1);
  background-color: #337ab7;
  border-color: #2e6da4;
}

.btn-primary:focus,
.btn-primary.focus {
  color: var(--vds-gray-1);
  background-color: #286090;
  border-color: #122b40;
}

.btn-primary:hover {
  color: var(--vds-gray-1);
  background-color: #286090;
  border-color: #204d74;
}

.btn-lg,
.btn-group-lg > .btn {
  padding: 10px 16px;
  font-size: var(--cc-font-size-18);
  line-height: 1.333;
  border-radius: var(--vds-round-6);
}

.btn-sm,
.btn-group-sm > .btn {
  padding: 5px 10px;
  font-size: var(--cc-font-size-12);
  line-height: 1.5;
  border-radius: 3px;
}

.btn-xs,
.btn-group-xs > .btn {
  padding: 1px 5px;
  font-size: var(--cc-font-size-12);
  line-height: 1.5;
  border-radius: 3px;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group,
.btn-group-vertical > .btn-group > .btn {
  display: block;
  float: none;
  width: 100%;
  max-width: 100%;
}

.btn-group-vertical > .btn-group > .btn {
  float: none;
}

.btn-group-vertical > .btn + .btn,
.btn-group-vertical > .btn + .btn-group,
.btn-group-vertical > .btn-group + .btn,
.btn-group-vertical > .btn-group + .btn-group {
  margin-top: -1px;
  margin-left: 0;
}

.btn-group-vertical > .btn:not(:first-child, :last-child) {
  border-radius: var(--vds-round-0);
}

.btn-group-vertical > .btn:first-child:not(:last-child) {
  border-radius: var(--vds-round-4) var(--vds-round-4) var(--vds-round-0) var(--vds-round-0);
}

.btn-group-vertical > .btn:last-child:not(:first-child) {
  border-radius: var(--vds-round-0) var(--vds-round-0) var(--vds-round-4) var(--vds-round-4);
}

.btn-group-vertical > .btn-group:not(:first-child, :last-child) > .btn {
  border-radius: var(--vds-round-0);
}

.btn-group-vertical > .btn-group:first-child:not(:last-child) > .btn:last-child,
.btn-group-vertical > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn-group:last-child:not(:first-child) > .btn:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.clearfix::after,
.dl-horizontal dd::after,
.container::after,
.container-fluid::after,
.row::after,
.form-horizontal .form-group::after,
.btn-toolbar::after,
.btn-group-vertical > .btn-group::after,
.nav::after,
.navbar::after,
.navbar-header::after,
.navbar-collapse::after,
.pager::after,
.panel-body::after,
.modal-header::after,
.modal-footer::after {
  clear: both;
}

.row {
  margin-right: -15px;
  margin-left: -15px;
}

.col-sm-3,
.col-sm-9,
.col-md-4,
.col-md-6,
.col-md-12,
.col-md-3,
.col-md-1,
.col-md-8 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

dl {
  margin-top: 0;
  margin-bottom: 20px;
}

dt,
dd {
  line-height: 1.4286;
}

dt {
  font-weight: var(--cc-font-weight-bold);
}

dd {
  margin-left: 0;
}

.dl-horizontal dd::before,
.dl-horizontal dd::after {
  display: table;
  content: " ";
}

.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #f9f9f9;
}

.table > thead > tr > td.success,
.table > tbody > tr > td.success,
.table > tfoot > tr > td.success,
.table > thead > tr > th.success,
.table > tbody > tr > th.success,
.table > tfoot > tr > th.success,
.table > thead > tr.success > td,
.table > tbody > tr.success > td,
.table > tfoot > tr.success > td,
.table > thead > tr.success > th,
.table > tbody > tr.success > th,
.table > tfoot > tr.success > th {
  background-color: #dff0d8;
}

.table-hover > tbody > tr > td.success:hover,
.table-hover > tbody > tr > th.success:hover,
.table-hover > tbody > tr.success:hover > td,
.table-hover > tbody > tr:hover > .success,
.table-hover > tbody > tr.success:hover > th {
  background-color: #d0e9c6;
}

.table > thead > tr > td.info,
.table > tbody > tr > td.info,
.table > tfoot > tr > td.info,
.table > thead > tr > th.info,
.table > tbody > tr > th.info,
.table > tfoot > tr > th.info,
.table > thead > tr.info > td,
.table > tbody > tr.info > td,
.table > tfoot > tr.info > td,
.table > thead > tr.info > th,
.table > tbody > tr.info > th,
.table > tfoot > tr.info > th {
  background-color: #d9edf7;
}

.table-hover > tbody > tr > td.info:hover,
.table-hover > tbody > tr > th.info:hover,
.table-hover > tbody > tr.info:hover > td,
.table-hover > tbody > tr:hover > .info,
.table-hover > tbody > tr.info:hover > th {
  background-color: #c4e3f3;
}

.table > thead > tr > td.warning,
.table > tbody > tr > td.warning,
.table > tfoot > tr > td.warning,
.table > thead > tr > th.warning,
.table > tbody > tr > th.warning,
.table > tfoot > tr > th.warning,
.table > thead > tr.warning > td,
.table > tbody > tr.warning > td,
.table > tfoot > tr.warning > td,
.table > thead > tr.warning > th,
.table > tbody > tr.warning > th,
.table > tfoot > tr.warning > th {
  background-color: #fcf8e3;
}

.table-hover > tbody > tr > td.warning:hover,
.table-hover > tbody > tr > th.warning:hover,
.table-hover > tbody > tr.warning:hover > td,
.table-hover > tbody > tr:hover > .warning,
.table-hover > tbody > tr.warning:hover > th {
  background-color: #faf2cc;
}

.table > thead > tr > td.danger,
.table > tbody > tr > td.danger,
.table > tfoot > tr > td.danger,
.table > thead > tr > th.danger,
.table > tbody > tr > th.danger,
.table > tfoot > tr > th.danger,
.table > thead > tr.danger > td,
.table > tbody > tr.danger > td,
.table > tfoot > tr.danger > td,
.table > thead > tr.danger > th,
.table > tbody > tr.danger > th,
.table > tfoot > tr.danger > th {
  background-color: #f2dede;
}

.table-hover > tbody > tr > td.danger:hover,
.table-hover > tbody > tr > th.danger:hover,
.table-hover > tbody > tr.danger:hover > td,
.table-hover > tbody > tr:hover > .danger,
.table-hover > tbody > tr.danger:hover > th {
  background-color: #ebcccc;
}

.table-hover > tbody > tr:hover {
  background-color: #f5f5f5;
}

blockquote {
  padding: 10px 20px;
  margin: 0 0 20px;
  font-size: 17.5px;
  border-left: 5px solid #eee;
}

h4,
.h4 {
  font-size: var(--cc-font-size-18);
  margin-top: 10px;
  margin-bottom: 10px;
}

hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #eee;
  box-sizing: content-box;
  height: 0;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

b,
strong {
  font-weight: bold;
}

.h3,
h3 {
  font-size: var(--cc-font-size-24);
}

.h1,
.h2,
.h3,
h1,
h2,
h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}

.btn-danger {
  color: var(--vds-gray-1);
  background-color: #d9534f;
  border-color: #d43f3a;
}

.btn-danger:focus,
.btn-danger.focus {
  color: var(--vds-gray-1);
  background-color: #c9302c;
  border-color: #761c19;
}

.btn-danger:hover {
  color: var(--vds-gray-1);
  background-color: #c9302c;
  border-color: #ac2925;
}

.container {
  margin-right: auto;
  margin-left: auto;
  max-width: 1240px;
  padding: 0;
}

table td[class*="col-"],
table th[class*="col-"] {
  position: static;
  display: table-cell;
  float: none;
}

.clearfix::before,
.clearfix::after,
.dl-horizontal dd::before,
.dl-horizontal dd::after,
.container::before,
.container::after,
.container-fluid::before,
.container-fluid::after,
.row::before,
.row::after,
.form-horizontal .form-group::before,
.form-horizontal .form-group::after,
.btn-toolbar::before,
.btn-toolbar::after,
.btn-group-vertical > .btn-group::before,
.btn-group-vertical > .btn-group::after,
.nav::before,
.nav::after,
.navbar::before,
.navbar::after,
.navbar-header::before,
.navbar-header::after,
.navbar-collapse::before,
.navbar-collapse::after,
.pager::before,
.pager::after,
.panel-body::before,
.panel-body::after,
.modal-header::before,
.modal-header::after,
.modal-footer::before,
.modal-footer::after {
  display: table;
  content: " ";
}

code {
  padding: 2px 4px;
  font-size: 90%;
  color: #c7254e;
  background-color: #f9f2f4;
  border-radius: var(--vds-round-4);
}

kbd {
  padding: 2px 4px;
  font-size: 90%;
  color: var(--vds-gray-1);
  background-color: #333;
  border-radius: 3px;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 25%);
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: var(--cc-font-weight-bold);
  box-shadow: none;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  appearance: button;
  cursor: pointer;
}

.text-center {
  text-align: center;
}

@media (min-width: 768px) {
  .form-inline .form-group {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;
  }

  .col-sm-3,
  .col-sm-9,
  .col-md-4,
  .col-md-6,
  .col-md-12,
  .col-md-3,
  .col-md-1,
  .col-md-8 {
    float: left;
  }

  .col-md-12 {
    width: 100%;
  }

  .col-sm-9 {
    width: 75%;
  }

  .col-sm-3 {
    width: 25%;
  }

  .col-md-4 {
    width: 34%;
  }

  .col-md-6 {
    width: 50%;
  }

  .col-md-3 {
    width: 25%;
  }

  .col-md-1 {
    width: 8.333%;
  }

  .col-md-8 {
    width: 66.666%;
  }

  .dl-horizontal dt {
    float: left;
    width: 160px;
    clear: left;
    text-align: right;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .dl-horizontal dd {
    margin-left: 180px;
  }

  .container {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}
