@import "bootstrap.pcss";
@import "normalize.pcss";
@import "fonts.pcss";
@import "auth.pcss";
@import "header.pcss";
@import "mobile-header.pcss";
@import "header-user-profile.pcss";
@import "sections.pcss";
@import "pagination.pcss";
@import "loader-spinner.pcss";
@import "page-title.pcss";
@import "input-checkbox.pcss";
@import "hr.pcss";
@import "buttons.pcss";
@import "input-field.pcss";
@import "dialer-stats.pcss";
@import "two-factor-top-hat.pcss";

* {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
}

.login-html {
  height: 100%;
  min-height: 100%;
}

html {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  background: var(--cc-color-white-7);
  color: var(--cc-color-base-font-color);
  text-shadow: 1px 1px 1px rgb(0 0 0 / 0.4%);
  min-height: 100%;
  overflow-x: hidden;
}

input {
  background-clip: padding-box;
}

label {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-weight: var(--cc-font-weight-normal);
  margin-bottom: 0;
}

.flex-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
}

#ui-state {
  .online {
    background: var(--vds-agent-status-online) !important;
  }

  .in_call {
    background: var(--vds-agent-status-in-call) !important;
  }
}

@define-mixin status-colors {
  &.offline {
    background: var(--vds-agent-status-offline);
  }

  &.online {
    background: var(--vds-agent-status-online);
  }

  &.ringing {
    background: var(--vds-agent-status-ringing);
  }

  &.in_call {
    background: var(--vds-agent-status-in-call);
  }

  &.postcall {
    background: var(--vds-agent-status-postcall);
  }

  &.hold {
    background: var(--vds-agent-status-hold);
  }

  &.transfer_from_ringing {
    background: var(--vds-agent-status-transfer-from-ringing);
  }

  &.transfer_from_talk {
    background: var(--vds-agent-status-transfer-from-talk);
  }

  &.transfer_to_ringing {
    background: var(--vds-agent-status-transfer-to-ringing);
  }

  &.transfer_to_talk {
    background: var(--vds-agent-status-transfer-to-talk);
  }

  &.int-out-ringing,
  &.int-out-talk,
  &.int-in-ringing,
  &.int-in-talk {
    background: var(--vds-agent-status-int-out-ringing);
  }

  &.away {
    background: var(--vds-agent-status-away);
  }

  &.ext-ringing,
  &.ext-talk {
    background: var(--vds-agent-status-ext-ringing);
  }

  &.dialing {
    background: var(--vds-agent-status-dialing);
  }

  &.outbound,
  &.dialer_waiting,
  &.dialer,
  &.dialer_talk {
    background: var(--vds-agent-status-outbound);
  }

  &.monitoring,
  &.listen {
    background-color: var(--vds-agent-status-monitoring);
  }

  &.whispering,
  &.whisper {
    background-color: var(--vds-agent-status-whispering);
  }

  &.barge_in,
  &.join {
    background-color: var(--vds-agent-status-barging-in);
  }
}

.add-link {
  display: table;
  margin-bottom: var(--vds-spacing-6);
}

table.table-status {
  tr th {
    @mixin status-colors;
  }
}

.status-circle {
  border-radius: var(--vds-circle);
  width: 16px;
  height: 16px;
}

.agent-list {
  margin-top: var(--vds-spacing-2);
  border: 1px solid var(--cc-color-light-gray-6);
  position: absolute;
  width: 30%;
  box-shadow: 5px 5px 5px var(--cc-color-gray-12);
  background-color: var(--cc-color-white);
  z-index: 1000;

  td {
    border-top: none !important;
  }

  thead {
    display: none;
  }
}

.agents-list-header {
  text-align: center;
}

span.plus-sign {
  color: var(--cc-color-blue-primary);
}

a {
  font-weight: var(--cc-font-weight-light);
  color: var(--cc-color-blue-primary);
  text-decoration: none;

  &:hover {
    color: var(--cc-color-blue-9);
    text-decoration: none;
  }

  &:focus {
    text-decoration: none;
  }
}

/* page container */
.max-1024 {
  padding: var(--vds-spacing-32) var(--vds-spacing-20) var(--vds-spacing-40);
  max-width: 1280px; /* 1240 + 20 padding left + 20 padding right */
  margin: 0 auto;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1;
  min-height: 100%;
  width: 100%;

  /* Add flex after load module, for hide some shaking in ui */
  display: none;
}

/* super admin pages @_container_class */
.admin-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1;
  padding: 0 var(--vds-spacing-30);
  padding-top: var(--vds-spacing-30);
}

@media screen and (max-width: 1260px) {
  .admin-container {
    padding: 0 var(--vds-spacing-10);
  }
}

table.callcenter {
  td.active {
    color: var(--cc-color-red-10);
    font-weight: var(--cc-font-weight-bold);
  }
}

/* stylelint-disable */
#blocking_numbers,
#campaigns,
#cards-app,
#cdr,
#cid-group,
#cid-groups,
#edit-team,
#edit_allowed_ip_2,
#incoming_numbers,
#keyword_groups,
#line,
#lines,
#media-file,
#media-files,
#new_dispatch_code,
#new_status,
#notification,
#report,
#sa_force-reload,
#sa_users,
#sr_topics,
#script_root,
#scripts_index_root,
#skill,
#skills,
#teams,
#user-page,
#users,
#wrap-up,
#billing-container,
#flows_index_root,
#sender-ids,
#sender-id-groups,
#audits,
#hlr_lookups,
#delete_recorgins,
#service-notifications,
#statuses,
#allowed_ips,
#canned_messages,
#call-suppression,
#number-validator,
#mynumbers-container,
#risk-checks,
#webhooks,
#digital-channels,
#whatsapp-templates,
#integrations,
.cdr-details,
.callcenter-settings {
  width: 100%;
}

/* stylelint-enable selector-id-pattern */

#two-factor-auth-setup {
  height: 100vh;
  background-color: var(--vds-gray-1);
}
