#audio {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.loader {
  color: var(--cc-color-gray-11);
  display: flex;
  align-self: center;
  flex-direction: column-reverse;
  justify-content: space-between;
  font-size: var(--cc-font-size-18);
  height: 90px;
}

.loader-spinner {
  border-radius: var(--vds-circle);
  width: 50px;
  height: 50px;
  display: flex;
  align-self: center;
  font-size: var(--cc-font-size-10);
  position: relative;
  text-indent: -9999em;
  border-top: 3px solid var(--cc-color-light-gray-19);
  border-right: 3px solid var(--cc-color-light-gray-19);
  border-bottom: 3px solid var(--cc-color-light-gray-19);
  border-left: 3px solid var(--cc-color-blue-primary);
  transform: translateZ(0);
  animation: load8 1.1s infinite linear;
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
