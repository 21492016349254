.page-title {
  font-size: var(--cc-font-size-35);
  margin: 0 0 25px;
  font-weight: var(--cc-font-weight-light);
  line-height: var(--cc-line-height-35);

  &.with-button {
    display: flex;
    align-items: center;

    .button {
      margin-left: auto;
    }
  }
}

.page-title__subtitle {
  line-height: var(--cc-line-height-24);
  margin: 0 0 var(--vds-spacing-10);
  font-size: var(--cc-font-size-18);
  font-weight: var(--cc-font-weight-light);
  text-transform: uppercase;
  border-bottom: 1px solid var(--cc-color-light-gray-12);

  &._light {
    color: var(--cc-color-gray-11);
  }
}
