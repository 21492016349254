/* stylelint-disable color-named, color-no-hex */
:root {
  --cc-sla-colors-red: #e00000;
  --cc-sla-colors-yellow: #ff9c0a;
  --cc-sla-colors-green-font-color: #57c460;
  --cc-sla-colors-red-font-color: #bc3c2e;
  --cc-sla-colors-yellow-font-color: #f57f17;
  --cc-sla-colors-red-background: #faefee;
  --cc-sla-colors-green-background: #eef9ef;
  --cc-sla-colors-yellow-background: #fef5ec;
}
